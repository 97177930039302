<template>
  <div class="header">
      <div class="header-top">
        <div class="container">
          <div @click="$router.push('/about')">
            联系我们
          </div>
          <el-dropdown>
  <span class="languages">
    Languages<i class="el-icon-arrow-down el-icon--right"></i>
  </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>English</el-dropdown-item>
                <el-dropdown-item>中文</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="header-bottom">
        <div class="container">
          <img class="logo" src="../assets/header/logo.png" @click="$router.push('/')">
          <div class="header-left">
            <template v-for="nav in navList" :key="nav.text">
              <router-link :to="nav.path">{{ nav.text }}</router-link>
            </template>
          </div>
        </div>
      </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="routeKey[$route.name]">{{routeKey[$route.name]}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data: function () {
    return {
      routeKey: {
        Home: '',
        Solution: '解决方案',
        Products: '产品',
        Support: '支持',
        News: '新闻中心',
        Case: '客户案例',
        About: '关于我们',
      },
      navList: [
        {
          text: '产品',
          path: '/products'
        },
        {
          text: '解决方案',
          path: '/solution'
        },
        {
          text: '客户案例',
          path: '/case'
        },
        {
          text: '新闻中心',
          path: '/news'
        },
        {
          text: '支持',
          path: '/support'
        },
      ]
    }
  },
  props: {
    msg: String
  },
  setup(){
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped type="scss">
.header{
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 11;
}
.header-top{
  border-bottom: 1px solid #EFEFF2;
  line-height: 40px;
  font-size: 12px;
}
.header-top .container {
  display: flex;
  justify-content: flex-end;

}
.breadcrumb{
  border-bottom: solid 1px #EFEFF2;
  background: #F7F7F7;
}
.breadcrumb >>> .el-breadcrumb{
  width: 1260px;
  margin: auto;
  height: 30px;
  line-height: 30px;
}
.languages{
  display: block;
  line-height: 40px;
  margin: 0 16px;
}
.header-bottom {
  border-bottom: 1px solid #EFEFF2;
  line-height: 70px;
  font-size: 14px;
}
.header-bottom .container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-bottom .logo{
  height: 36px;
}
.header-bottom a{
  display: inline-block;
  margin: 0 40px;
  line-height: 70px;
  height: 70px;
  text-decoration: none;
  color: #303030;
}
.header-bottom .router-link-active{
  color: #B2292D;
  border-bottom: solid;
}
</style>
<style>
.breadcrumb .el-breadcrumb__item:last-child .el-breadcrumb__inner{
  color: #E70303;
}
.breadcrumb .is-link{
  color: #303030;
  font-weight: 400;
}
.breadcrumb .is-link:hover{
  color: #303030;
}
.el-breadcrumb__separator{
  color: #303030;
}
</style>