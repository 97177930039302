<template>
  <div class="news">
<!--    <div class="banner">-->
<!--      <img src="../assets/news/banner.png">-->
<!--    </div>-->
    <div class="container">
      <div class="card-top">
        <div class="category active">
          全部
        </div>
        <div class="category">
          产品动态
        </div>
        <div class="category">
          安全播报
        </div>
        <div class="category">
          创宇资讯
        </div>
        <div class="category">
          服务公告
        </div>
        <div class="category">
          直播干货
        </div>
        <div class="category">
          漏洞情报
        </div>
      </div>
      <div class="card-bottom">
        <div class="card">
          <img src="../assets/news/news-1.png">
          <div class="title">全球最危险恶意软件僵尸网络</div>
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中。
          </p>
        </div>
        <div class="card">
          <img src="../assets/news/news-2.png">
          <div class="title">全球最危险恶意软件僵尸网络</div>
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中。
          </p>
        </div>
        <div class="card">
          <img src="../assets/news/news-1.png">
          <div class="title">全球最危险恶意软件僵尸网络</div>
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中。
          </p>
        </div>
        <div class="card">
          <img src="../assets/news/news-2.png">
          <div class="title">全球最危险恶意软件僵尸网络</div>
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'News',
  components: {
  }
}
</script>

<style scoped type="scss">

.news{
  background-image: url("../assets/home/bg-2.png");
  padding: 80px 0 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.news .banner img{
  width: 100%;
  display: block;
}

.news .card-top{
  //margin-top: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #2B2F3A;
  text-align: center;
  padding: 0 20px 40px 0;
  display: flex;
  justify-content: flex-start;
}
.news .category{
  //background-image: linear-gradient(153deg, #d0caca 0%, #cf6d70 100%);
  border-radius: 32px;
  width: 110px;
  margin-right: 10px
}
.news .active, .info .active:hover{
  background-image: linear-gradient(153deg, #B2292D 0%, #C53135 100%);
  color: #FFFFFF;
}

.news .card-bottom{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.news .card{
  background: #FFFFFF;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  padding: 20px;
  width: 29%;
  margin-bottom: 20px;
}
.news .title{
  line-height: 58px;
  font-size: 18px;
  color: #3D4F65;
}

.news .card img{
  width: 100%;
  border-radius: 10px;
}

.news p{
  font-size: 12px;
  color: #A9ADB8;
  letter-spacing: 0;
  text-align: justify;
  line-height: 22px;
}
.news .btn{
  right: 25px;
  bottom: 30px;
  position: absolute;
  background-image: linear-gradient(153deg, #B2292D 0%, #C53135 100%);
  border-radius: 10px;
  line-height: 50px;
  display: inline-block;
  padding: 0 33px;
  color: #fff;
}

</style>
