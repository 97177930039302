<template>
  <div class="info">
    <div class="container">
      <div class="title">
        联系支持
      </div>
      <div class="sub-title">
        通过野火科技在线支持门户，实时聊天或我们的全球客户支持中心网络，与我们的产品专家24x7全天候联系。
      </div>
      <div class="card-wrap">
        <div class="card">
          <img class="card-title" src="../assets/support/title-1.png">
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。</p>
          <div class="btn-wrap">
            <div class="btn">
              登录 >
            </div>
          </div>
        </div>
        <div class="card">
          <img class="card-title" src="../assets/support/title-2.png">
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。</p>
          <div class="btn-wrap">
            <div class="btn">
              与支持人员聊天 >
            </div>
          </div>
        </div>
        <div class="card">
          <img class="card-title" src="../assets/support/title-3.png">
          <p>全球最危险的恶意软件僵尸网络在全球执法部门共同合作，历经两年的不懈打击下终于被取缔。在欧洲刑警组织、美国联邦调查局、英国国家犯罪局和其他机构的联合行动中，终于控制和取缔了 Emotet 僵尸网络的基础设施。</p>
          <div class="btn-wrap">
            <div class="btn">
<!--              <img src="../assets/support/ph.png" alt="">-->
              +63 180 1100 1888
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Support',
  components: {
  }
}
</script>

<style scoped type="scss">
body{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
.container{
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
}
.banner img{
  width: 100%;
}

.title{
  font-size: 40px;
  line-height: 68px;
  color: #3D4F65;
  text-align: center;
}
.sub-title{
  font-size: 16px;
  color: #7B7B7B;
  text-align: center;
}
.info{
  background-image: url("../assets/home/bg-1.png");
  padding: 54px 0 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.info .card-wrap{
  display: flex;
  justify-content: center;
}
.info .card{
  background: #fff;
  font-size: 14px;
  color: #A9ADB8;
  text-align: justify;
  line-height: 24px;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  margin-top: 40px;
  padding: 12px 20px 18px;
  max-width: 280px;
  margin-right: 20px;
  min-height: 310px;
  position: relative;
}
.info .card:last-child{
  margin-right: 0;
}
.info .card .card-title{
  height: 40px;
}
.info p{
  font-size: 12px;
  color: #A9ADB8;
  letter-spacing: 0;
  text-align: justify;
  line-height: 22px;
  margin-top: 30px;
}
.info .btn-wrap{
  position: absolute;
  bottom: 0;
  height: 76px;
  right: 0;
  left: 0;
  text-align: center;

}
.info .btn{
  width: 150px;
  line-height: 44px;
  background: #EEF1F3;
  border: 0 solid #CBCBCE;
  border-radius: 10px;
  font-size: 14px;
  color: #7B8287;
  text-align: center;
  margin: 0 auto;

}




</style>
