<template>
  <el-carousel height="550px" class="banner">
    <el-carousel-item v-for="item in banner" :key="item">
<!--      <h3 class="small">{{ item }}</h3>-->
      <img :src="item" />
    </el-carousel-item>
  </el-carousel>
  <MarqueePart/>
  <div class="info">
    <div class="container">
      <div class="title">
        野火科技  为您保驾护航
      </div>
      <div class="sub-title">
        Powerful and stable ，Escort for you
      </div>
      <div class="card">
        <div class="card-left">
          <div class="category" v-for="(tab, index) in infoTabList" :key="tab" @click="infoCurTab=index" :class="{active: infoCurTab === index}">
            {{ tab }}
          </div>
        </div>
        <CardRight :info="infoList[infoCurTab]" />
      </div>
    </div>
  </div>
  <div class="solution">
    <div class="container">
      <div class="title">
        全行业安全解决方案
      </div>
      <div class="sub-title">
        Security Solutions
      </div>
      <div class="card-top">

        <div class="category" v-for="(tab, index) in solutionTabList" :key="tab" @click="solutionCurTab=index" :class="{active: solutionCurTab === index}">
          {{ tab }}
        </div>
      </div>
      <div class="card-bottom">
        <div class="title">
          {{ solutionList[solutionCurTab].title }}
        </div>
        <p>
          {{ solutionList[solutionCurTab].content }}</p>
        <div class="btn" @click="$router.push('/solution')">
          了解更多 >
        </div>
      </div>
    </div>
  </div>
  <div class="sample">
    <div class="container">
      <div class="title">
        客户案例
      </div>
      <div class="sub-title">
        Successful Cases
      </div>
      <div class="mayun-slogan">“ We’re able to take a proactive approach and the visibility we now have enables us to respond quicker and with more precision than we ever could before.”</div>
      <div class="mayun">
        <img src="../assets/home/mayun.png">
        马云 - 阿里巴巴集团主要创始人
      </div>
      <div class="btn" @click="$router.push('/case')">
        了解更多 >
      </div>
      <div class="sample-logo">
        <img src="../assets/home/sample-logo1.png">
        <img src="../assets/home/sample-logo2.png">
        <img src="../assets/home/sample-logo3.png">
        <img src="../assets/home/sample-logo4.png">
        <img src="../assets/home/sample-logo5.png">
      </div>
    </div>
  </div>
</template>

<script>
import MarqueePart from '../components/Marquee.vue'
import CardRight from '../components/CardRight.vue'
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'Home',
  components: {
    MarqueePart,
    CardRight,
  },
  data(){
    return {
      banner: [
        require('../assets/home/banner.png'),
        require('../assets/home/banner.png'),
        require('../assets/home/banner.png')
      ],
      infoCurTab: 0,
      infoTabList: [
        '火云',
        '野马浏览器',
        '火聚CDN',
        '非聊',
        '火密',
        '火眼监控',
      ],
      infoList: [
        {
          list:[
            {
              title: '客户面临的问题与挑战\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '传统办公方式越来越难以满足灵活、高效、协同的办公要求，并面临部署管理复杂、数据安全难以保障、资源未充分利用、可靠性与可用性不高等问题。'
            },
            {
              title: '安全高效的办公环境\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案\n。'
            },
            {
              title: '客户价值\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '融合桌面云不但提供了高效办公环境，而且还结合实践经验，将安全认证、病毒防御、文档加密、水印、操作录像等安全方案整合到方案中，为企业提供一站式的安全办公体验'
            },
            {
              title: '应用场景\n',
              titleImg:   require('../assets/home/logo-title.png'),
              content: '提供多种场景化解决方案，满足企业不同办公诉求。'
            }
          ]
        },{
          list:[
            {
              title: '域名防劫持，DDOS高防\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '100%解决及预防各类劫持问题，专业解决DNS 防劫持问题\n高级防御提醒，网络攻击不再是问题\n。'
            },
            {
              title: '域名污染解封\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '被墙被封被污染，通通无需担心。'
            },
            {
              title: '域名免ICP备案',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '永久免备案，域名访问畅通无阻。GPU加速，还您极速上网体验内嵌神秘功能，更懂你。'
            },
            {
              title: 'CDN加速\n',
              titleImg:   require('../assets/home/logo-title.png'),
              content: '提升浏览体验，各地区线路加速。'
            }
          ]
        },{
          list:[
            {
              title: '应用安全一 (功能描述）\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: ''
            },
            {
              title: '应用安全二 (功能描述）\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: ''
            },
            {
              title: '应用安全三 (功能描述）\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '应用安全四 (功能描述）\n',
              titleImg:   require('../assets/home/logo-title.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            }
          ]
        },{
          list:[
            {
              title: '消息安全\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '银行级别加密技术，本地，协议，数据库，全链路动态加密。'
            },
            {
              title: '管理安全',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '支持企业级部署，自定义皮肤； 独立后台，数据安全实时掌控；IP控制，安全区外禁止登陆。'
            },
            {
              title: '无缝迁移',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '尊重用户使用习惯，集众多IM之优点，体验更顺畅。'
            },
            {
              title: '效率加倍',
              titleImg:   require('../assets/home/logo-title.png'),
              content: '文件收发加速；支持话题快速搜索；更多功能持续迭代中。'
            }
          ]
        },{
          list:[
            {
              title: '企业级部署',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '独立部署，支持安全域隔离，分布式管理，自定义审批流程。'
            },
            {
              title: '透明加密',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '内部透明，文件加解密无感知，不改变用户使用习惯。'
            },
            {
              title: '系统日志审计',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '提供完成日志管理，支持查询、导出、备份及生成报表。'
            },
            {
              title: '文件打印快照\n',
              titleImg:   require('../assets/home/logo-title.png'),
              content: '支持打印时文件快照上传，防止伪装文件名打印。'
            }
          ]
        },{
          list:[
            {
              title: '全方位电脑监控',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '网络活动、文件资料、远程桌面、黑名单行为，无死角监控。'
            },
            {
              title: '实时监控与远程控制\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '支持同时对30台电脑实时远程监控，形成电视墙；支持随时接管键盘鼠标。'
            },
            {
              title: '资料长期留档\n',
              titleImg:  require('../assets/home/logo-title.png'),
              content: '每台电脑在每个设定时间段生成截图存档服务器，支持回溯。'
            },
            {
              title: '实时屏幕水印',
              titleImg:   require('../assets/home/logo-title.png'),
              content: '防截屏、防拍照泄漏，实时水印支持定制风格、显示内容。'
            }
          ]
        },
      ],
      solutionCurTab: 0,
      solutionTabList: [
        '等级保护 2.0',
        '云桌面',
        '团队协同解决方案',
        '全球穿透访问',
        '安全攻防服务和培训',
      ],
      solutionList: [
        {
          title: '等保2.0安全解决方案',
          content: '“安全”与“效率”越来越受到企业的重视；借助云桌面与安全方案的融合，提供弹性、安全的一站式办公解决方案。',
          link: '',
        },
        {
          title: '云桌面安全解决方案',
          content: '传统办公方式越来越难以满足灵活、高效、协同的办公要求，并面临部署管理复杂、数据安全难以保障、资源未充分利用、可靠性与可用性不高等问题。',
          link: '',
        },
        {
          title: '团队协同解决方案',
          content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案。',
          link: '',
        },
        {
          title: '全球穿透访问 ',
          content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案。',
          link: '',
        },
        {
          title: '安全攻防服务和培训',
          content: '资产安全、安全解决方案、应急响应、安全产品、安全运营、安全培训、安全咨询、渗透攻击、威胁情报、钓鱼攻击等安全服务。',
          link: '',
        },
      ],
    }
  },
  methods: {
    beforeMoving(dire) {
      console.log("before", dire);
    },
    afterMoving(obj) {
      console.log("after", obj);
    },
  },
  setup() {
    const state = reactive({
      data: [
        require('../assets/home/banner.png'),
        require('../assets/home/banner.png'),
        require('../assets/home/banner.png')
      ],
    });
    return {
      ...toRefs(state),
    };
  },
})
</script>

<style scoped type="scss">
body{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
.container{
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
}
.Carousel {
  width: 100% !important;
}

::v-deep .CarouselItem .container{
  width: 100%;
  max-width: initial;
}
.CarouselItem .container img{
  width: 100%;
}
::v-deep .indicator{
  
}
.banner img{
  height: 550px;
}
.title{
  font-size: 40px;
  line-height: 68px;
  color: #3D4F65;
  text-align: center;
}
.sub-title{
  font-size: 16px;
  color: #7B7B7B;
  text-align: center;
}
.info{
  background-image: url("../assets/home/bg-1.png");
}
.solution{
  background-image: url("../assets/home/bg-2.png");
}
.sample{
  background-image: url("../assets/home/bg-3.png");
  text-align: center;
}
.info, .solution, .sample{
  padding: 54px 0 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.info .card{
  background: #fff;
  font-size: 14px;
  color: #A9ADB8;
  text-align: justify;
  line-height: 24px;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  display: flex;
  margin-top: 40px;
}

.info .card-left{
  background: #FAFCFF;
  font-size: 16px;
  color: #2B2F3A;
  line-height: 44px;
  text-align: center;
  width: 210px;
  padding: 19px 30px;
}


.info .category{
  /*background-image: linear-gradient(153deg, #d0caca 0%, #cf6d70 100%);*/
  border-radius: 32px;
  width: 150px;
  margin: 0 auto 36px;
  cursor: pointer;
}
.info .category:last-child{
  margin: 0 auto 0;
}
.info .active, .info .active:hover{
  background-image: linear-gradient(153deg, #B2292D 0%, #C53135 100%);
  color: #FFFFFF;
}

.solution .card-top{
  margin-top: 40px;
  line-height: 50px;
  font-size: 16px;
  color: #2B2F3A;
  text-align: center;
  padding: 0 20px 40px;
  display: flex;
  justify-content: space-between;
}
.solution .category{
  /*background-image: linear-gradient(153deg, #d0caca 0%, #cf6d70 100%);*/
  border-radius: 32px;
  width: 150px;
  cursor: pointer;
}
.solution .active, .info .active:hover{
  background-image: linear-gradient(153deg, #B2292D 0%, #C53135 100%);
  color: #FFFFFF;
}

.solution .card-bottom{
  padding: 20px 30px 75px 20px;
  background-image: linear-gradient(-45deg, #F3F4F8 0%, #FCFDFF 100%);
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  position: relative;
  text-align: left;
  height: 96px;
}
.solution .card-bottom .title{
  font-size: 18px;
  color: #262A2F;
  text-align: left;
  line-height: 25px;
  margin-bottom: 10px;
}
.solution p{
  font-size: 14px;
  color: #A9ADB8;
  line-height: 24px;
}
.solution .btn{
  right: 25px;
  bottom: 30px;
  position: absolute;
  background-image: linear-gradient(153deg, #B2292D 0%, #C53135 100%);
  border-radius: 10px;
  line-height: 50px;
  display: inline-block;
  padding: 0 33px;
  color: #fff;
}
.sample .title{
  color: #fff;
}
.sample .sub-title{
  color: #AEAEAE;
  margin-bottom: 40px;
}
.sample .mayun img{
  vertical-align: top;
  width: 29px;
  height: 29px;
}
.sample .mayun{
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 29px;
}
.sample .mayun-slogan{
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  margin-bottom: 10px;
}
.sample .btn{
  font-size: 14px;
  color: #B2292D;
  background: #FFFFFF;
  margin: 60px auto 30px;
  line-height: 50px;
  border-radius: 10px;
  display: inline-block;
  padding: 0 33px;
}
.sample-logo{
  display: flex;
  justify-content: center;
}

.sample-logo img{
  height: 68px;
  margin: 0 27px;
}
.el-carousel__indicator.is-active button{
  background-color: #B2292D;
}
</style>
