<template>
  <div class="banner">
    <img src="../assets/solution/banner.png">
  </div>
  <div class="solution">
    <div class="container">
      <div class="title">
        野火科技  五大支柱
      </div>
      <div class="sub-title">
        随时与我们的专家联系，扩大您的团队和专业知识
      </div>
      <div class="card-wrap">
        <div class="card" v-for="item in techBaseList" :key="item.title">
          <img :src="item.icon">
          <div class="title">{{ item.title }}</div>
          <p>{{ item.content }}</p>
          <div class="btn">
            了解更多 >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sample">
    <div class="container">
      <div class="title">
        战略伙伴关系
      </div>
      <div class="sub-title">
        阿里巴巴等集团公司与野火科技宣布建立长期战略合作伙伴关系
      </div>
      <div class="mayun-slogan">“我们能够采取积极的方法，而现在我们拥有的可见性使我们能够比以往任何时候都更快，更准确地做出响应。”</div>
      <div class="btn">
        了解更多 >
      </div>
      <div class="sample-logo">
        <img src="../assets/home/sample-logo1.png">
        <img src="../assets/home/sample-logo2.png">
        <img src="../assets/home/sample-logo3.png">
        <img src="../assets/home/sample-logo4.png">
        <img src="../assets/home/sample-logo5.png">
      </div>
    </div>
  </div>
  <div class="info">
    <div class="container">
      <div class="title">
        为什么要与野火科技合作？
      </div>
      <div class="sub-title">
        现代网络攻击者精巧，并且不断发展其技术
      </div>
      <div class="card">
        <div class="card-left">
          <div class="title">野火科技</div>
          <img src="../assets/solution/title-logo.png">
          <p>
            野火科技（WILDFIRES）是网络安全解决方案的组合，使组织能够评估，优化和不断改进安全程序。野火科技团队运用数十年的一线经验，对新兴的全球威胁因素有所了解，因此您可以管理整体网络安全风险。
          </p>
        </div>

        <CardRight :info="infoList[0]" />
      </div>
    </div>
  </div>
</template>

<script>

import CardRight from '../components/CardRight.vue'
export default {
  name: 'Solution',
  data: function () {
    return {
      techBaseList: [
        {
          title: '云桌面   ',
          icon: require('../assets/product/yunzhuomian.png'),
          content: '传统办公方式越来越难以满足灵活、高效、协同的办公要求，并面临部署管理复杂、数据安全难以保障、资源未充分利用、可靠性与可用性不高等问题。',
          path: '/'
        },
        {
          title: '等保2.0整体解决方案',
          icon: require('../assets/product/jiejuefangan.png'),
          content: '安全”与“效率”越来越受到企业的重视；借助云桌面与安全方案的融合，提供弹性、安全的一站式办公解决方案。',
          path: '/'
        },
        {
          title: '团队协同解决方案  ',
          icon: require('../assets/product/tuanduixietong.png'),
          content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案。',
          path: '/'
        },
        {
          title: '全球穿透访问 ',
          icon: require('../assets/product/quanqiu.png'),
          content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案。',
          path: '/'
        },
        {
          title: '安全攻防服务和培训',
          icon: require('../assets/product/anquan.png'),
          content: '资产安全、安全解决方案、应急响应、安全产品、安全运营、安全培训、安全咨询、渗透攻击、威胁情报、钓鱼攻击等安全服务。',
          path: '/'
        },
      ],
      infoList: [
        {
          list:[
            {
              title: '终端安全一 (功能描述）\n',
              titleImg:  require('../assets/solution/title-1.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '终端安全二 (功能描述）\n',
              titleImg:  require('../assets/solution/title-2.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '终端安全三 (功能描述）\n',
              titleImg:  require('../assets/solution/title-3.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '终端安全四 (功能描述）\n',
              titleImg:   require('../assets/solution/title-4.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            }
          ]
        },
      ]
    }
  },
  components: {
    CardRight
  }
}
</script>

<style scoped type="scss">
body{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
.container{
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
}
.banner img{
  width: 100%;
  display: block;
}

.title{
  font-size: 40px;
  line-height: 68px;
  color: #3D4F65;
  text-align: center;
}
.sub-title{
  font-size: 16px;
  color: #7B7B7B;
  text-align: center;
}
.info{
  background-image: url("../assets/solution/bg-3.png");
}
.solution{
  background-image: url("../assets/solution/bg-1.png");
}
.sample{
  background-image: url("../assets/solution/bg-2.png");
  text-align: center;
}
.info, .solution, .sample{
  padding: 54px 0 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.info .card{
  background: #fff;
  font-size: 14px;
  color: #A9ADB8;
  text-align: justify;
  line-height: 24px;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  display: flex;
  margin-top: 40px;
}

.info .card-left{
  background: #FAFCFF;
  font-size: 16px;
  color: #2B2F3A;
  line-height: 44px;
  text-align: left;
  width: 310px;
  padding: 30px 24px;
}
.info .card-left .title {
  font-size: 34px;
  color: #3D4F65;
  margin-bottom: 5px;
  text-align: left;
  line-height: 48px;
}
  .card-left img{
  width: 90px;
  margin-bottom: 10px;
  line-height: 1;
}
.info .card-left p{
  margin-top: 0;
  font-size: 14px;
  color: #7B7B7B;
  letter-spacing: 0;
  text-align: justify;
  line-height: 24px;
}
::v-deep.info .card-right{
  max-width: 930px;
}
::v-deep.info .item{
  max-width: 405px;
}

.solution .card-wrap{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.solution .card{
  flex: 1;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  text-align: center;
  padding: 36px 20px 49px;
  margin-right: 20px;
}
.solution .card:last-child{
  margin-right: 0;
}
.solution img{
  width: 80px;
}
.solution .card .title{
  font-size: 18px;
  color: #2B2F3A;
  line-height: 65px;
}
.solution p{
  font-size: 14px;
  color: #A9ADB8;
  line-height: 24px;
  text-align: justify;
  height: 130px;
}
.solution .btn{
  background: #EEF1F3;
  border: 0.5px solid #CBCBCE;
  border-radius: 10px;
  line-height: 44px;
  display: inline-block;
  width: 100%;
  max-width: 150px;
  font-size: 14px;
  color: #7B8287;
  margin-top: 30px;
}
.solution .btn:hover{
  background: #B2292D;
  color: #fff;
  cursor: pointer;
}
.sample .title{
  color: #fff;
}
.sample .sub-title{
  color: #C89092;
  margin-bottom: 40px;
  font-weight: 300;
}

.sample .mayun-slogan{
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  margin-bottom: 10px;
}
.sample .btn{
  font-size: 14px;
  color: #B2292D;
  background: #FFFFFF;
  margin: 40px auto 40px;
  line-height: 50px;
  border-radius: 10px;
  display: inline-block;
  padding: 0 33px;
}
.sample-logo{
  display: flex;
  justify-content: center;
}

.sample-logo img{
  height: 68px;
  margin: 0 27px;
}
</style>
