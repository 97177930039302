import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Solution from '../views/Solution.vue'
import Products from '../views/Products.vue'
import Support from '../views/Support.vue'
import News from '../views/News.vue'
import Case from '../views/Case.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/solution',
    name: 'Solution',
    component: Solution
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/case',
    name: 'Case',
    component: Case
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
export default router
