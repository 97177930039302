<template>
  <Header/>
  <router-view />

  <main-footer/>
</template>
<script>
import MainFooter from "@/components/MainFooter";
import Header from "@/components/Header";
export default {
  name: 'App',
  components: {
    Header,
    MainFooter
  }
}
</script>
<style type="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
body{
  margin: 0;
  padding: 112px 0 0;
}
p{
  margin: 0;
}

.container{
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
}


</style>
