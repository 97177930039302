<template>
  <div class="banner">
    <img src="../assets/product/banner.png">
  </div>
  <div class="solution">
    <div class="container">
      <div class="title">
        野火科技产品
      </div>
      <div class="sub-title">
        WILDFIRES PRODUCTS
      </div>
      <div class="card-wrap">
        <el-row :gutter="20">
          <el-col :span="12"  v-for="item in ProductList" :key="item.title">
            <div class="box-card">
              <img :src="item.icon">
              <div class="right_txt">
                <div class="title">{{ item.title }}</div>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
  <div class="sample">
    <div class="container">
      <div class="title">
        战略伙伴
      </div>
      <div class="mayun-slogan">“ 野火科技技术套件与管理型防御相结合，提供了一定程度的覆盖范围和可见性，我的组织无法通过其他方式（例如，雇用必要的员工）来进行复制。”</div>
      <div class="mayun-slogan">-CL，一家美国中型制造商的信息安全经理</div>
    </div>
  </div>
  <div class="info">
    <div class="container">
      <div class="title">
        其他野火科技产品
      </div>
      <div class="sub-title">
        ADDITIONAL WILDFIRES PRODUCTS
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="title_txt">企业安全</div>
          <div class="cont_txt">安全协调员</div>
          <p>集成和自动化整个IT基础架构中的技术和流程。</p>
          <div class="cont_txt">文件保护</div>
          <p>检测并阻止网络文件共享中的恶意软件。</p>
          <div class="cont_txt">中小型企业</div>
          <p>为您的成长型企业提供简单，负担得起的网络安全解决方案。</p>
          <div class="cont_txt">野火科技安全套件</div>
          <p>为中端市场客户提供完全集成的企业级安全性，以检测，</p><p>
            保护和响应网络，电子邮件和端点威胁。</p>
        </el-col>
        <el-col :span="8">
          <div class="title_txt">法证与调查</div>
          <div class="cont_txt">法证与调查</div>
          <p>使用智能，先进的调查和分析工具来消除威胁。</p>
          <div class="cont_txt">网络取证</div>
          <p>几乎实时地开发攻击环境并促进快速调查。</p>
          <div class="cont_txt">恶意软件分析</div>
          <p>提供安全的虚拟环境，以测试，重放，表征和记录高级恶意软件。</p>
          <div class="cont_txt">端点取证</div>
          <p>通过智能驱动的全面端点可见性，快速预防，检测和响应威胁。</p>
        </el-col>
        <el-col :span="8">
          <div class="title_txt">安全性针对</div>
          <div class="cont_txt">云</div>
          <p>全面的云解决方案，可提供保护，可见性，检测和服务。</p>
          <div class="cont_txt">教育</div>
          <p>保护您宝贵的学生，教职员工数据和知识产权。</p>
          <div class="cont_txt">金融服务</div>
          <p>首要的网络安全挑战是保护客户资产的义务。</p>
          <div class="cont_txt">政府</div>
          <p>保护患者数据，临床研究和关键基础设施。</p>
          <div class="cont_txt">卫生保健</div>
          <p>应对对任务关键型政府网络和关键基础架构的不断发展的威胁。</p>
          <div class="cont_txt">工业控制系统</div>
          <p>应对对任务关键型政府网络和关键基础架构的不断发展的威胁。</p>
          <div class="cont_txt">零售</div>
          <p>将技术与威胁情报和丰富的经验相结合，以防止网络攻击。</p>
        </el-col>
      </el-row>
    </div>
  </div>
  <div class="contact">
    <h1>准备开始了吗？</h1>
    <p>我们的安全专家会随时帮助您处理事件或回答有关我们的咨询以及托管的检测和响应服务的问题。</p>
    <div class="contact_box">
      <div class="box">
        <img src="../assets/product/phone.png" />
        <span>+63 180 1100 1888</span>
      </div>
      <div class="box">
        <img src="../assets/product/email.png" />
        <span>+63 180 1100 1888</span>
      </div>
      <div class="box">
        <img src="../assets/product/telegram.png" />
        <span>+63 180 1100 1888</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solution',
  data: function () {
    return {
      ProductList: [
        {
          title: '火云   ',
          icon: require('../assets/product/icon/huoyun.png'),
          content: '传统办公方式越来越难以满足灵活、高效、协同的办公要求，并面临部署管理复杂、数据安全难以保障、资源未充分利用、可靠性与可用性不高等问题。',
          path: '/'
        },
        {
          title: '野马浏览器',
          icon: require('../assets/product/icon/yema.png'),
          content: '安全”与“效率”越来越受到企业的重视；借助云桌面与安全方案的融合，提供弹性、安全的一站式办公解决方案。',
          path: '/'
        },
        {
          title: '火聚CDN  ',
          icon: require('../assets/product/icon/safe.png'),
          content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案。',
          path: '/'
        },
        {
          title: '非聊 ',
          icon: require('../assets/product/icon/feiliao.png'),
          content: '安全”与“效率”越来越受到企业的重视；通过前后端软硬件的深度融合，提供安全、高效、协同的桌面云办公平台，成为企业一站式办公主流选择方案。',
          path: '/'
        },
        {
          title: '火密',
          icon: require('../assets/product/icon/huomi.png'),
          content: '资产安全、安全解决方案、应急响应、安全产品、安全运营、安全培训、安全咨询、渗透攻击、威胁情报、钓鱼攻击等安全服务。',
          path: '/'
        },
        {
          title: '火眼监控',
          icon: require('../assets/product/icon/huoyan.png'),
          content: '资产安全、安全解决方案、应急响应、安全产品、安全运营、安全培训、安全咨询、渗透攻击、威胁情报、钓鱼攻击等安全服务。',
          path: '/'
        },
      ],
      infoList: [
        {
          list:[
            {
              title: '终端安全一 (功能描述）\n',
              titleImg:  require('../assets/solution/title-1.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '终端安全二 (功能描述）\n',
              titleImg:  require('../assets/solution/title-2.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '终端安全三 (功能描述）\n',
              titleImg:  require('../assets/solution/title-3.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            },
            {
              title: '终端安全四 (功能描述）\n',
              titleImg:   require('../assets/solution/title-4.png'),
              content: '为建站提供域名、云主机、备案等基础服务，也可以选择CDN加速服务让用户获得极速体验。为建站提供基础服务。为建站提供域名、云主机、备案等基础服务。'
            }
          ]
        },
      ]
    }
  },
}
</script>

<style scoped type="scss">
body{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
.container{
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
}
.banner img{
  width: 100%;
  display: block;
}

.title{
  font-size: 40px;
  line-height: 68px;
  color: #3D4F65;
  text-align: center;
}
.sub-title{
  font-size: 16px;
  color: #7B7B7B;
  text-align: center;
}
.info{
  background-image: url("../assets/solution/bg-3.png");
}
.solution{
  background-image: url("../assets/solution/bg-1.png");
}
.sample{
  background-image: url("../assets/solution/bg-2.png");
  text-align: center;
}
.info, .solution, .sample{
  padding: 54px 0 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.info .card{
  background: #fff;
  font-size: 14px;
  color: #A9ADB8;
  text-align: justify;
  line-height: 24px;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  display: flex;
  margin-top: 40px;
}

.info .card-left{
  background: #FAFCFF;
  font-size: 16px;
  color: #2B2F3A;
  line-height: 44px;
  text-align: left;
  width: 310px;
  padding: 30px 24px;
}
.info .card-left .title {
  font-size: 34px;
  color: #3D4F65;
  margin-bottom: 5px;
  text-align: left;
  line-height: 48px;
}
  .card-left img{
  width: 90px;
  margin-bottom: 10px;
  line-height: 1;
}
.info .card-left p{
  margin-top: 0;
  font-size: 14px;
  color: #7B7B7B;
  letter-spacing: 0;
  text-align: justify;
  line-height: 24px;
}
::v-deep.info .card-right{
  max-width: 930px;
}
::v-deep.info .item{
  max-width: 405px;
}

.solution .card-wrap{
  margin-top: 40px;
}
.solution .box-card{
  display: flex;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  margin-top: 40px;
}
.solution img{
  width: 80px;
  height: 80px;
  margin: 10px;
  float: left;
}

.solution .right_txt{
  width: 460px;
  float: right;
}
.solution .box-card .title{
  font-size: 16px;
  color: #2B2F3A;
  line-height: 45px;
  text-align: left;
}
.solution p{
  font-size: 12px;
  color: #A9ADB8;
  line-height: 24px;
  text-align: justify;
  height: 60px;
}
.solution .btn{
  background: #EEF1F3;
  border: 0.5px solid #CBCBCE;
  border-radius: 10px;
  line-height: 44px;
  display: inline-block;
  width: 100%;
  max-width: 150px;
  font-size: 14px;
  color: #7B8287;
  margin-top: 30px;
}
.sample .title{
  color: #fff;
  font-size: 40px;
  margin-bottom: 40px;
}
.sample .sub-title{
  color: #C89092;
  margin-bottom: 40px;
  font-weight: 300;
}

.sample .mayun-slogan{
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  margin-bottom: 10px;
}
.sample .btn{
  font-size: 14px;
  color: #B2292D;
  background: #FFFFFF;
  margin: 40px auto 40px;
  line-height: 50px;
  border-radius: 10px;
  display: inline-block;
  padding: 0 33px;
}
.sample-logo{
  display: flex;
  justify-content: center;
}

.sample-logo img{
  height: 68px;
  margin: 0 27px;
}
.info .title_txt{
  font-size: 22px;
  color: #2B2F3A;
}
.info p{
  font-size: 12px;
  color: #A9ADB8;
}
.info .cont_txt{
  font-size: 16px;
  color: #2B2F3A;
}
.contact{
  background-image: url("./../assets/product/shutterstock_434284534.png");
  text-align: center;
  height: 300px;
}
.contact h1{
  padding-top: 40px;
  color: #ffffff;
}
.contact p{
  color: #8598B1;
}
.contact_box{
  display: flex;
  width: 850px;
  height: 80px;
  margin: 20px auto;
  background: #334357;
  box-shadow: 0 6px 13px 0 rgba(35,47,62,0.15);
  border-radius: 10px;
}
.contact_box .box{
  flex: 1;
  padding: 25px;
  color: #ffffff;
}
.contact_box .box img{
  width: 20px;
}
</style>
