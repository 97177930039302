<template>
  <div class="news">
    <div class="container">
      <div class="title">
        野火科技客户的成功案例
      </div>
      <div class="sub-title">
        随时与我们的专家联系，扩大您的团队和专业知识
      </div>
      <div class="card-bottom">
        <div class="card">
          <div class="logo">
            <img src="../assets/case/alilogo.png"></div>
          <img class="title-pic" src="../assets/case/title-1.png">
<!--          <div class="title">全球最危险恶意软件僵尸网络</div>-->
          <p>网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。
          </p>
        </div>
        <div class="card">
          <div class="logo">
            <img src="../assets/case/zhengtai.png"></div>
          <img class="title-pic" src="../assets/case/title-2.png">
<!--          <div class="title">全球最危险恶意软件僵尸网络</div>-->
          <p>网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。网络主管讨论了对阿里巴巴Fire Eye解决方案如何轻松集成并生产质量警报。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Case',
  components: {
  }
}
</script>

<style scoped type="scss">

.news{
  background-image: url("../assets/home/bg-2.png");
  padding: 60px 0 127px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.title{
  font-size: 40px;
  line-height: 68px;
  color: #3D4F65;
  text-align: center;
}
.sub-title{
  font-size: 16px;
  color: #7B7B7B;
  text-align: center;
}
.news .card-bottom{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}
.news .card{
  background: #ffffff;
  background-size: 100% 103%;
  box-shadow: 0 6px 10px 0 rgba(115,132,172,0.08);
  border-radius: 10px;
  padding: 20px;
  width: 46%;
  margin-bottom: 20px;
}
.news .card .title{
  line-height: 58px;
  font-size: 18px;
  color: #3D4F65;
}

.news .card .logo img{
  width: 100%;
  border-radius: 10px;
}

.news .card .logo{
  width: 208px;
  height: 72px;
  margin: auto;
}
.news .card .title-pic{
  height: 50px;
  margin: auto;
}
.news p{
  font-size: 12px;
  color: #A9ADB8;
  letter-spacing: 0;
  text-align: justify;
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.news .btn{
  right: 25px;
  bottom: 30px;
  position: absolute;
  background-image: linear-gradient(153deg, #B2292D 0%, #C53135 100%);
  border-radius: 10px;
  line-height: 50px;
  display: inline-block;
  padding: 0 33px;
  color: #fff;
}

</style>
