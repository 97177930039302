<template>
  <div class="marquee">
    <div class="container">
<!--      <p>123456789</p>-->
      <span v-html="notice"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarqueeC',
  data() {
    return {
      notice: '我是广播文字内容，哈哈哈哈，你习惯不行啊，我页不知道啊啊啊啊啊'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped type="scss">
.marquee{
  position: relative;
  background: #3D4F65;
  width: 100%;
  line-height: 40px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.container{
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  display: block;
  text-align: center;
  overflow: hidden;
  -webkit-animation: myMove 30s linear infinite;
  animation: myMove 30s linear infinite;
}
/*.container p{*/
/*  font-size: 18px;*/
/*  color: #fff;*/
/*  display: inline-block;*/
/*  !* 宽度 等于容器的宽度 *!*/
/*  width: 100%;*/
/*  height: 30px;*/
/*  border-left:1px solid #fff;*/
/*  line-height: 30px;*/
/*  text-align: center;*/
/*  margin-top: 5px;*/
/*  background: #000;*/
/*}*/
@-webkit-keyframes myMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2500px);
  }
}

@keyframes myMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2500px);
  }
}
</style>
