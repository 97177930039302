<template>
  <div class="footer">
    <div class="footer-top">
      <div class="container">
        <div class="footer-item">
          <div class="title">
            关注我们
          </div>
          <img class="qr-code" src="../assets/footer/qrcode.png">
        </div>
        <div class="footer-item">
          <div class="title">
            联系方式
          </div>
          <div class="content">
            <p>热线电话：000 - 0000000</p>
            <p>手机号码：1234567890</p>
            <p>Telegram：123@abcsdf</p>
            <p>地址：香港XXXXXXX84-86号章记大厦1302</p>
          </div>
        </div>
        <div class="footer-item">
          <div class="title"  @click="$router.push('/about')">
            关于
          </div>
          <div class="content">
            <p @click="$router.push('/about')">关于我们</p>
            <p @click="$router.push('/about')">联系我们</p>
            <p>荣誉资质</p>
          </div>
        </div>
        <div class="footer-item">
          <div class="title">
            合作
          </div>
          <div class="content">
            <p>云创计划</p>
            <p>合作伙伴计划</p>
            <p>资源推荐</p>
          </div>
        </div>
        <div class="footer-item">
          <div class="title">
            链接
          </div>
          <div class="content">
            <p>云XXXX</p>
            <p>合XXXX</p>
            <p>更多</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-center">
      <div class="container">
        <img src="../assets/footer/footer-logo1.png">
        <img src="../assets/footer/footer-logo2.png">
        <img src="../assets/footer/footer-logo3.png">
        <img src="../assets/footer/footer-logo4.png">
        <img src="../assets/footer/footer-logo5.png">
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        &copy;野火科技有限公司 版权所有
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mainFooter',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped type="scss">
.footer{
  background: #262626;
  width: 100%;
  color: #A2A2A2;
  line-height: 26px;
  font-size: 12px;
  text-align: left;
}
.footer-top .title{
  font-size: 16px;
  line-height: 62px;
  color: #FFFFFF;
  text-align: left;
}
.footer-top .qr-code{
  width: 121px;
  height: 121px;
}
.footer-top .content{
  line-height: 26px;
  font-size: 12px;
}
.footer-item{
  text-align: left;
}
.footer-top .container {
  border-bottom: 1px solid #383838;
  display: flex;
  justify-content: space-between;
}
.footer-center{
  line-height: 40px;
  font-size: 12px;
  text-align: center;
}
.footer-center .container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #383838;
  text-align: center;
  height: 83px;
}
.footer-center .container img {
  height: 38px;
}
.footer-bottom {
  line-height: 60px;
  font-size: 14px;
}
.footer-bottom .container{
  text-align: center;
}
</style>
