<template>
  <div class="card-right">
    <div class="item" v-for="item in info.list" :key="item.title">
      <div class="card-title">
<!--        <img :src="item.titleImg">-->
        {{ item.title }}
      </div>
      <p>{{ item.content }}</p>
      <img class="more" @click="$router.push('/products')" src="../assets/home/more.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardRight',
  props: ['info'],
  created: function () {
    console.log('info is: ')
  },
  data: function (){
    return {
    }
  }
}
</script>


<style scoped type="scss">
.info .card-right{
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.info .card-title{
  line-height: 23px;
  font-size: 16px;
  color: #2B2F3A;
}
.info p{
  font-size: 14px;
  color: #A9ADB8;
  letter-spacing: 2.1px;
  text-align: justify;
  line-height: 24px;
  margin-top: 10px;
}
.info .card-title img{
  width: 22px;
  height: 23px;
  vertical-align: text-bottom;
  margin-right: 10px;
}
.info .item{
  margin: 10px;
  width: 50%;
  max-width: 455px;
  background-image: linear-gradient(-45deg, #F9F9F9 0%, #F6F8FB 100%);
  border-radius: 8px;
  padding: 20px;
  position: relative;
}
.info .more{
  width: 24px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
</style>
