import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import Carousel from 'v3-carousel'
const app = createApp(App)
app.use(router).use(Carousel)
app.use(ElementPlus)
app.mount('#app')
